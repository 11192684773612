import React from 'react'

import '../styles/work.css'
import cardImg1 from '../images/card_image_1.jpg'
import cardImg2 from '../images/card_image_2.jpg'
import cardImg3 from '../images/card_image_3.jpg'

export default function Work() {
    return (
        <div className="container-fluid work-fluid text-center">
            <section className="container container-work">
                <h1>Our Work</h1>
                <hr className="color-red1" />
                <hr className="color-red2" />
                <div className="card-container">
                    <div className="first-second-card-container">
                        <div className="card1" data-aos="flip-right" data-aos-delay="300">
                            <img src={cardImg1} alt="school" />
                            <h6>Education</h6>
                            <p>
                            Mingma Foundation has successfully conducted another educational 
                            project at Shree Rastriya Adharbhut Vidhyalaya, Upardangadi, 
                            Chitwan where we distributed medicines and stationary to the 
                            students of the school
                            </p>
                            <a 
                                href="https://www.facebook.com/pg/MingmaFoundation/about/?ref=page_internal"
                                target="_blank"
                                alt="migma foundation facebook page"
                                >More +</a>
                        </div>
                        <div className="card2" data-aos="flip-right" data-aos-delay="600">
                            <img src={cardImg2} alt="school" />
                            <h6>Education</h6>
                            <p>Pranav Lama, five years old is living with his grandparents.
                                His parents left him when he was just eleven days old. Our foundation 
                                is helping him to continue his education. 
                            </p>
                                <a 
                                href="https://www.facebook.com/pg/MingmaFoundation/about/?ref=page_internal"
                                target="_blank"
                                alt="migma foundation facebook page"
                                >More +</a>
                        </div>
                    </div>
                    <div className="third-card-container">
                        <div className="card3" data-aos="flip-right" data-aos-delay="900">
                            <img src={cardImg3} alt="school" />
                            <h6>Education</h6>
                            <p>
                            Prem Bhujel, Bal Bhujel, and Om Bhujel moved from Udaypur Gaighat to the capital 
                            with their parents. Since thier parents cannot afford their education, our foundaion 
                            helping them out!
                            </p>
                            <a 
                                href="https://www.facebook.com/pg/MingmaFoundation/about/?ref=page_internal"
                                target="_blank"
                                alt="migma foundation facebook page"
                                >More +</a>
                        </div>
                    </div>
                </div>
                <div className="container video-container">
                    <div className="video-wrapper">
                        <div className="video video1">
                            <iframe  
                            className="iframe-video"
                            src="https://www.youtube.com/embed/EJfxqoQnfoo" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen>
                            </iframe>
                        </div>
                        <div className="video video2">
                            <iframe  
                            className="iframe-video"
                            src="https://www.youtube.com/embed/mMjBjfFG6ho" 
                            frameBorder="0" 
                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
                            allowFullScreen>
                            </iframe>
                        </div>
                    </div>
                </div>
                    <a 
                        href="https://www.facebook.com/pg/MingmaFoundation/about/?ref=page_internal"
                        target="_blank"
                        alt="migma foundation facebook page"
                        className="work-view-more"
                        >View More 
                    </a>
            </section>
        </div>
    )
}
