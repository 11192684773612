import React from 'react'
import GoogleMapReact from 'google-map-react'

import '../styles/map.css'
export default function Map() {

    return (
        <div className="container-fluid map-fluid">
            <div className="container map-container">
            <iframe  
            data-aos="fade-up" data-aos-delay="500"
            className= "google-map-bansbari" 
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d48783.81783119972!2d85.34335212766868!3d27.701476722238617!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39eb194f84acc8eb%3A0x6b7e62199aff3c79!2sBansbari%2C%20Kathmandu%2044600%2C%20Nepal!5e0!3m2!1sen!2sus!4v1594587552984!5m2!1sen!2sus"></iframe>
            </div>      
        </div>
    )
}
