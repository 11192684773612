import React from 'react'

import '../styles/about.css'
import aboutImage from '../images/mingma_about_img.jpg'

export default function About() {
    return (
        <div className="container-fluid about-fluid">
            <section className="container about-container">
                <div className="about-image" data-aos="fade-right" data-aos-delay="300">
                    <img src={aboutImage} alt="education for all" />
                </div>
                <div className="about-desc" data-aos="fade-left" data-aos-delay="600">
                    <h1>Our Mission</h1>
                    <p>
                        Founded on 2018, Mingma Foundation believes that 
                        technology must be integrated into the classroom and 
                        in each and every curriculum. Technology has the 
                        potential to provide significant tools for both 
                        teachers and students to more efficiently, 
                        effectively, and creatively solve problems, to 
                        develop thinking skills, to organize and process 
                        information, to communicate ideas, to learn new 
                        information, to reinforce learning, and to apply 
                        knowledge to future life situations.
                    </p>
                    <div className="about-learn-more">
                        <a 
                        href="https://www.facebook.com/pg/MingmaFoundation/about/?ref=page_internal"
                        target="_blank"
                        alt="migma foundation facebook page"
                        >Learn More</a>
                    </div>
                </div>
            </section>
        </div>
    )
}
