import React from 'react'
import {Link} from 'react-router-dom'
import '../styles/footer.css'


export default function Footer() {
    const handleonClick=()=>{
        window.scrollTo({
            top:0,
            behavior:'smooth'
        })
    }
    return (
        <section className="container-fluid footer-fluid">
            <div className="scroll-top text-center" onClick={handleonClick}>
                <i className="far fa-arrow-alt-circle-up fa-3x"></i>
            </div>
            <div className="container footer-container">
                <h2>Follow us on social media. We need your support!</h2>
                <div className="social-media-icon">
                    <a data-aos="zoom-in"
                        data-aos-delay="200"
                    href="https://www.facebook.com/pg/MingmaFoundation/about/?ref=page_internal" alt="facebook" target="_blank">
                        <i className="fab fa-facebook-square"></i>
                    </a>
                    <a data-aos="zoom-in-right"
                        data-aos-delay="400"
                    href="https://www.instagram.com/mingmafoundation/" alt="instagram" target="_blank">
                        <i className="fab fa-instagram"></i>
                    </a>

                    <a data-aos="zoom-in"
                        data-aos-delay="600"
                    href="https://www.linkedin.com/in/mingma-sherpa-202033123" alt="linkedIn" target="_blank">
                        <i className="fab fa-linkedin"></i>
                    </a>
                    <a data-aos="zoom-int"
                        data-aos-delay="800"
                    href="mailto:mingmafoundation@gmail.com" alt="linkedIn" target="_blank">
                        <i className="fas fa-envelope-square"></i>
                    </a>

                </div>
                <div className="footer-main-content">
                    <div className="footer-about">
                        <h4>About Us</h4>
                        <p>Mingma Foundation is a Non-profit Organization.Our 
                            Foundation is dedicated to serve our nation through 
                            social service in the feild of education</p>
                    </div>
                    <div className="footer-contact-us">
                        <h4>Contact Us</h4>
                        <p>Bansbari 03</p>
                        <p>Kathmandu,Nepal</p>
                        <p>+977 981-1119197</p>
                        <p>
                            mingmafoundation@gmail.com
                        </p>
                    </div>
                    <div className="footer-support-us">
                        <h4>Support Us</h4>
                        <p>Your donation will go along way...</p>
                        <div className="footer-donate-btn">
                            <a 
                            href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=3XKXTZRYC76VS&item_name=Education&currency_code=USD&source=url" 
                            target="_blank">Donate</a>
                        </div>
                    </div>
                </div>
                <div className="copy-right">
                    <p>&copy; {new Date().getFullYear()} Mingma Foundation | <a href="https://www.bibashkc.com/" alt="bibash kc">bibashkc.com</a></p>
                </div>
            </div>    
        </section>
    )
}
