import React,{useEffect} from 'react'

import '../styles/landing.css'

export default function Landing() {
    useEffect(()=>{
        let progressBar = document.querySelector('.progress-div')
        progressBar.style.top=0
        window.addEventListener('scroll',()=>{
            let max = document.body.scrollHeight-window.innerHeight
            progressBar.style.width=`${(window.pageYOffset/max)*100}%`
        }) 
    },[])
    return (
        <div className="container-fluid landing-fluid">
            <div className="container landing-container"></div>
            <div className="landing-image">
                <div className="landing-jumbotron" data-aos="flip-up">
                    <h1>We are Mingma Foundation</h1>
                    <hr className="color-white"/>
                    <p>
                        Mingma Foundation believes that technology must be 
                        integrated into the classroom and in each and every 
                        curriculum.
                    </p>
                    <div className="landing-learn-more">
                        <a 
                        href="https://www.facebook.com/pg/MingmaFoundation/about/?ref=page_internal"
                        target="_blank"
                        alt="migma foundation facebook page"
                        >Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    )
}
