import React,{Fragment, useEffect} from 'react';
import './App.css';
import {BrowserRouter as Router } from 'react-router-dom'

import Navbar from './components/Navbar'
import Landing from './components/Landing'
import About from './components/About'
import Work from './components/Work'
import Donate from './components/Donate'
import Impact from './components/Impact'
import Map from './components/Map'
import Footer from './components/Footer'

import Aos from 'aos'
import 'aos/dist/aos.css'

function App() {
  useEffect(()=>{
    Aos.init({duration:2000})
  },[])
  return (
    <Router>
      <Fragment>
        <Navbar />
        <Landing />
        <About />
        <Work />
        <Donate />
        <Impact />
        <Map />
        <Footer />
      </Fragment>
    </Router>
  );
}

export default App;
