import React,{useEffect} from 'react'

import '../styles/impact.css'

export default function Impact() {
    return (
        <section className="container-fluid impact-fluid">
            <div className="container impact-container">
                <h1>Our Impact</h1>
                <hr className="color-red1" />
                <hr className="color-red2" />
                <div className="column-wrapper">
                    <div className="impact-col-left">
                        <div className="col-left-impact1" data-aos="zoom-in" data-aos-delay="200">
                            <h2 className="counter" data-target="500">7</h2>
                            <p>student enrolled</p>
                        </div>
                        <div className="col-left-impact2" data-aos="zoom-in" data-aos-delay="400">
                            <h2 className="counter" data-target="300">17</h2>
                            <p>teachers trained</p>
                        </div>
                    </div>
                    <div className="impact-col-right">
                        <div className="col-left-impact1" data-aos="zoom-in" data-aos-delay="600">
                            <h2 className="counter" data-target="2000">2000</h2>
                            <p>people fed</p>
                        </div>
                        <div className="col-left-impact2" data-aos="zoom-in" data-aos-delay="800">
                            <h2 className="counter" data-target="300">5000</h2>
                            <p>stationery distributed</p>
                        </div>
                    </div>
                </div>
                <a 
                    className="impact-view-more"
                    href="https://www.facebook.com/pg/MingmaFoundation/about/?ref=page_internal"
                    target="_blank"
                    alt="migma foundation facebook page"
                    >View More</a>
            </div>
        </section>
    )
}
