import React from 'react'
import {Link} from 'react-router-dom'

import '../styles/donate.css'

export default function Donate() {
    return (
        <section className="container-fluid donate-fluid">
            <div className="donate-bg-image">
               <div className="black-opacity">
               <i className="fas fa-hand-holding-usd fa-3x" data-aos="fade-down" data-aos-delay="300"></i>
                <h2>Make a Difference, Make a donation today!</h2>
                    <p>Our Non-profit Organization is dedicated to serve our nation 
                        through social service in the feild of education.
                    </p>
                    <a
                    data-aos="fade-up" 
                    data-aos-delay="300"
                    className="donate-now-button"
                    href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=3XKXTZRYC76VS&item_name=Education&currency_code=USD&source=url" target="_blank" 
                    alt="migma foundation facebook page"
                    >Donate Now</a>
               </div>
            </div>
        </section>
    )
}
