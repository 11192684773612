import React,{useEffect} from 'react'
import {Link} from 'react-router-dom'

import newLogo from '../images/mingma_foundation_new_logo.png'

import '../styles/navbar.css'

const Navbar=()=>{
    const handleonClick=(target)=>{
        let offsetTop;
        if(target === "work"){
            offsetTop=document.querySelector(".work-fluid").offsetTop-50
        }else{
            offsetTop=document.querySelector(".map-fluid").offsetTop-50
        }
        window.scrollTo({
            behavior:'smooth',
            left:0,
            top:offsetTop
        })
    }
    return (
        <nav className="navbar fixed-top navbar-expand-lg main-nav">
            <Link to="/" className="navbar-brand">
                <img className="img-fluid nav-logo" src={newLogo} alt="migma foundation logo" />
            </Link>
            <a href="https://www.paypal.com/cgi-bin/webscr?cmd=_donations&business=3XKXTZRYC76VS&item_name=Education&currency_code=USD&source=url" target="_blank" className="donate-button" >
                Donate
            </a>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation" >
                <i className="fas fa-bars" style={{color:"black", fontSize:"25px"}}></i>
            </button>
            <div className="collapse navbar-collapse" id="navbarNav">
                <ul className="navbar-nav ml-auto"> 
                    <li className="nav-item" data-toggle="collapse" data-target="#navbarNav" onClick={()=>handleonClick("work")}>
                        <Link className="nav-link" to="/menu/appetizer">Work</Link>
                    </li>
                    <li className="nav-item" data-toggle="collapse" data-target="#navbarNav" onClick={(e)=>handleonClick("contact")}>
                        <Link className="nav-link" to="/menu/appetizer">Contact</Link>
                    </li>
                </ul>
            </div>
            <div className="progress-div"></div>
        </nav>
    )
}

export default Navbar